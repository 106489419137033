@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

.background-image {
  background-image: url('../../assets/images/GRL7053_webp.webp');
}

* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div#root > div > div:first-child#sidebar-menu-container {
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(69, 72, 84, 0.08);
  border-color: rgba(121, 122, 124, 0);
}

div#root > div > div:first-child > ul.MuiList-root a {
  margin: 6px 0;
}

div#root > div > div:first-child hr.MuiDivider-root {
  display: none;
}

div#root > div#content-container > .MuiToolbar-root {
  height: 30px;
}
